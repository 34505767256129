import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'

import { PageHeader, styles } from '../components/utils'
import background from '../assets/images/about.jpg'
import topPic from '../assets/images/skyline.jpg'
import avatar from '../assets/images/avatar.jpg'

const AboutPage = () => (
  <Layout>
    <SEO title="About" keywords={['blog', 'travel', 'life']} />

    <HeaderWrapper img={background}>
      <ProfileWrapper>
        <div className="card">
          <div className="header">
            <div className="header-img"/>
          </div>
          <div className="avatar">
            <div className="avatar-img" />
          </div>
          <div className="info">
            <div className="info-text">
              <h2>Mukesh M.</h2>
              <h4>
                Life through the eyes of a British (sometimes) gentleman living and loving New York City and this wonderful world!
                <p/>
                Come join in for the ride!
              </h4>
            </div>
          </div>
        </div>
      </ProfileWrapper>
    </HeaderWrapper>
  </Layout>
)

const HeaderWrapper = styled.div`
 & {
    min-height: calc(100vh - 55.78px);
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), 
      url(${background}) center/cover fixed no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
 }
`

const ProfileWrapper = styled.div`
  & {
    position: relative;
    margin: 10vw auto;
    width: 500px;
    max-width: 500px;
    box-sizing:border-box;
    overflow: hidden;
    background-color: #EEE;
    /* box-shadow: 0 0 50px rgba(34,34,34,0.6); */
    border-radius:2%;
    height: auto;
    max-height: 800px;
  }

  .header {
    position: relative;
    width: 100%;
    padding-bottom: 60%;
    height: 0;
  
    .header-img {
      background-image: url(${topPic});
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-position: 0 50%;
    }
  }

  .avatar {
    width: 55%;
    height: auto;
    margin: -20% auto 10px auto;
    position: relative;
    
    .avatar-img {
      background-image: url(${avatar}); 
      border-radius: 50%;
      width: 100%;
      padding-bottom: 100%;
      background-size: 100%;
      background-position: 0 50%;
      border: solid 3px white;
    }
  }

  .info {
    padding-top: 2rem;

    .info-text {
      text-align:center;
    }
  }
`

export default AboutPage
